import React from "react"
import { Link } from "react-router-dom"
import moment from "moment-timezone"
import {
    ButtonElement,
    PageHelmetElement,
    PrimaryText,
    SpinnerElement,
} from "nirvana-react-elements"

import { ROUTES_CONFIG } from "config/routes.config"
import { GENERAL_CONFIG } from "../../config/general.config"
import { useAppSelector } from "../../store/selectors/app.selector"
import { profileSelector } from "../../store/selectors/profile.selector"
import { useIsFeatureFlagEnabled } from "../../hooks/isFeatureFlagEnabled.hook"
import { FeatureFlag } from "../../config/featureFlags.config"

import exitIcon from "../../assets/images/icons/exit-red.svg"

export const AccountDetailsComponent: React.FunctionComponent = () => {
    const profile = useAppSelector(profileSelector)

    const isBillerGroupsEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_BILLER_GROUPS
    )

    return (
        <div className="max-w-840px mx-auto relative">
            <PageHelmetElement
                title="Account Details"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            {!profile ? (
                <SpinnerElement containerClassName="mt-60px text-center" />
            ) : (
                <div>
                    <PrimaryText typography="text">
                        Nirvana Member since{" "}
                        {moment(profile.createdAt).format(
                            GENERAL_CONFIG.defaultMomentDateFormat
                        )}
                    </PrimaryText>

                    <div className="mt-24px">
                        <div className="flex items-center sm:block">
                            <PrimaryText
                                className="w-180px mr-10px"
                                typography="textSemibold"
                            >
                                Name
                            </PrimaryText>

                            <PrimaryText
                                typography="text"
                                className="mouseflow-ignore"
                            >
                                {profile.name}
                            </PrimaryText>
                        </div>

                        <div className="flex items-center mt-8px sm:block">
                            <PrimaryText
                                className="w-180px mr-10px"
                                typography="textSemibold"
                            >
                                Email Address
                            </PrimaryText>
                            <PrimaryText
                                typography="text"
                                className="mouseflow-ignore"
                            >
                                {profile.email}
                            </PrimaryText>
                        </div>

                        <div className="flex items-center mt-8px sm:block">
                            <PrimaryText
                                className="w-180px mr-10px"
                                typography="textSemibold"
                            >
                                Password
                            </PrimaryText>
                            <PrimaryText typography="text">
                                ************
                            </PrimaryText>
                        </div>

                        {isBillerGroupsEnabled &&
                        profile.billerGroups?.length ? (
                            <div className="flex items-center mt-8px sm:block">
                                <PrimaryText
                                    className="w-180px mr-10px"
                                    typography="textSemibold"
                                >
                                    Biller Groups
                                </PrimaryText>
                                <PrimaryText typography="text">
                                    {profile.billerGroups
                                        .map(item => item.name)
                                        .join(", ")}
                                </PrimaryText>
                            </div>
                        ) : null}
                    </div>

                    <PrimaryText className="mt-24px">
                        To edit, please contact{" "}
                        <a
                            className="text-bold text-brand-vibrantPurple!"
                            href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                        >
                            {GENERAL_CONFIG.supportEmail}
                        </a>
                    </PrimaryText>

                    <div className="mt-32px">
                        <Link to={ROUTES_CONFIG.logoutUrl}>
                            <ButtonElement
                                label="Log out"
                                type="default"
                                isDanger
                                htmlType="button"
                                size="large"
                                icon={exitIcon}
                            />
                        </Link>
                    </div>
                </div>
            )}
        </div>
    )
}

import { ISelectRenderedOption, NIRVANA_COLORS } from "nirvana-react-elements"

import flagHighIcon from "../assets/images/icons/flag-high.svg"
import flagMediumIcon from "../assets/images/icons/flag-medium.svg"
import flagLowIcon from "../assets/images/icons/flag-low.svg"
import flagNoneIcon from "../assets/images/icons/flag-ok.svg"

export enum CoveragePortalFlagType {
    INACTIVE_POLICY = "INACTIVE_POLICY", // plan_status=inactive
    EXPIRING_SOON = "EXPIRING_SOON", // plan_end_date < 30 days current date
    UNKNOWN_COSTSHARE = "UNKNOWN_COSTSHARE", // 206 + unknown_costshare response error code
    UNKNOWN_ACCUMULATOR = "UNKNOWN_ACCUMULATOR", // 206 + unknown_remaining response error code
    POLICY_NOT_FOUND = "POLICY_NOT_FOUND", // 404
    REVIEW_REQUIRED = "REVIEW_REQUIRED", // 400, 500, 502
    DEMOGRAPHICS_MISMATCH = "DEMOGRAPHICS_MISMATCH", // request and response demographics don't match (payer_id, member_id, dob, first/last names)
    THIRD_PARTY_PAYER_DETECTED = "THIRD_PARTY_PAYER_DETECTED", // third_party_payer is not unknown
    ADDITIONAL_POLICY_DETECTED = "ADDITIONAL_POLICY_DETECTED", // additional_policy is not unknown
    UNKNOWN_VISITS = "UNKNOWN_VISITS", // visits_total is null; applies to PT only
    VISITS_MET = "VISITS_MET", // visits_remaining = 0; applies to PT only

    // TODO implement after MVP
    NEW_PATIENT = "NEW_PATIENT", // header that is passed through: EHR integration -> coverage API -> nirvana-backend

    // TODO implement after MVP
    // should compare to previous check, ideally in the future should appear just when happens first time
    // Copayment is present and remaining_stop_loss = 0 -> OOP Max Met
    // Coinsurance is present and remaining_deductible = 0 -> Deductible Met
    // Coinsurance is present and remaining_stop_loss = 0 0> OOP Max Met
    DEDUCTIBLE_MET = "DEDUCTIBLE_MET",
    OOP_MAX_MET = "OOP_MAX_MET",
}

export enum AvailableCoverageHistorySortField {
    timestamp = "timestamp",
    memberFirstName = "memberFirstName",
    memberLastName = "memberLastName",
    planName = "planName",
    flags = "flags",
    overrideExpirationDate = "overrideExpirationDate",
    nextAppointment = "nextAppointment",
}

export enum AvailableCoverageHistorySortDirection {
    ASC = "ASC",
    DESC = "DESC",
}

export enum CoveragePortalFlagStatus {
    NEW = "NEW",
    DONE = "DONE",
}

export enum CoveragePortalCommentType {
    USER = "USER",
    SYSTEM = "SYSTEM",
}

export enum CoveragePortalCommentRenderingType {
    GENERAL = "GENERAL",
    OVERRIDE = "OVERRIDE",
}

export enum PoliciesViewType {
    HISTORY_SEARCH = "HISTORY_SEARCH",
    REPORTS = "REPORTS",
    OVERRIDES = "OVERRIDES",
    PLAN_YEAR_RESETS = "PLAN_YEAR_RESETS",
}

export enum PolicyDenialRisk {
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
    NONE = "NONE",
}

export enum PolicyHeaderDragDirection {
    BEFORE = "BEFORE",
    AFTER = "AFTER",
}

export enum ResetBenefitStatus {
    DETECTED_ACTION_REQUIRED = "DETECTED_ACTION_REQUIRED",
    DETECTED_NO_ACTION_REQUIRED = "DETECTED_NO_ACTION_REQUIRED",
    NOT_DETECTED = "NOT_DETECTED",
}

export const POLICIES_CONFIG = {
    planYearResetsTargetYear: 2025,

    singlePolicyHistoryAllowedRunCheckStatusCodes: [
        200, 202, 206, 429, 500, 502,
    ],

    availablePaginationOptions: [
        {
            value: 20,
            displayValue: "20 results per page",
        },
        {
            value: 50,
            displayValue: "50 results per page",
        },
        {
            value: 100,
            displayValue: "100 results per page",
        },
    ] as ISelectRenderedOption[],

    exportPoliciesBaseName: "policies",

    minMemberDataFields: 2,
    minOverallDataFields: 3, // includes 2 range dates + some field

    defaultGoBackMonths: 3,
    defaultReportsGoBackDays: 7,

    searchMemberDataFilters: [
        "memberId",
        "memberDob",
        "memberFirstName",
        "memberLastName",
    ] as (keyof IPoliciesListFiltersData)[],

    get filtersHiddenDisplayKeys(): (keyof IPoliciesListFiltersData)[] {
        return ["hasOverrides", "planResetSoon"]
    },

    get filtersDisplayMapping(): Record<
        keyof IPoliciesListFiltersData,
        string
    > {
        return {
            dateFrom: "Check Date (From)",
            dateTo: "Check Date (To)",
            memberId: "Member ID",
            memberDob: "Member DOB",
            memberFirstName: "Member First Name",
            memberLastName: "Member Last Name",
            network: "Network",
            modality: "Modality",
            payer: "Payer (ID or Name)",
            patientType: "Patient Type",
            patientId: "Patient ID",
            planName: "Plan Name",
            groupName: "Group Name",
            groupId: "Group ID",
            flags: "Alert(s)",
            hasOverrides: "With Overrides Only",
            planResetSoon: "With Plan Year Reset Only",
            resetBenefitsStatus: "Reset Benefits Status",
            planType: "Plan Type",
            nextAppointmentDateFrom: "Next Appointment Date (From)",
            nextAppointmentDateTo: "Next Appointment Date (To)",
            planEndDateFrom: "Plan End Date (From)",
            planEndDateTo: "Plan End Date (To)",
            insuranceType: "Insurance Type",
        }
    },

    get filtersMouseFlowIgnoreFields(): (keyof IPoliciesListFiltersData)[] {
        return [
            "memberId",
            "memberDob",
            "memberFirstName",
            "memberLastName",
            "patientId",
            "planName",
            "groupName",
            "groupId",
        ]
    },

    mediumAttentionFlagSeverityCheck: 40,
    highAttentionFlagSeverityCheck: 70,

    get policyDenialRiskIconsMapping(): Record<PolicyDenialRisk, string> {
        return {
            [PolicyDenialRisk.NONE]: flagNoneIcon,
            [PolicyDenialRisk.HIGH]: flagHighIcon,
            [PolicyDenialRisk.MEDIUM]: flagMediumIcon,
            [PolicyDenialRisk.LOW]: flagLowIcon,
        }
    },

    get flagTypeMappings(): Record<
        CoveragePortalFlagType,
        IFlagMappingConfiguration
    > {
        return {
            [CoveragePortalFlagType.INACTIVE_POLICY]: {
                severity: 100,
                label: "Policy Expired",
            },
            [CoveragePortalFlagType.POLICY_NOT_FOUND]: {
                severity: 90,
                label: "Policy Not Found",
            },
            [CoveragePortalFlagType.VISITS_MET]: {
                severity: 80,
                label: "Visit Limit Met",
            },
            [CoveragePortalFlagType.REVIEW_REQUIRED]: {
                severity: 70,
                label: "Review Required",
            },
            ///////////////////////////////////////////////////// END HIGH, START MEDIUM severity
            [CoveragePortalFlagType.EXPIRING_SOON]: {
                severity: 60,
                label: "Policy Expires Soon",
            },
            [CoveragePortalFlagType.UNKNOWN_VISITS]: {
                severity: 55,
                label: "Unknown Visits",
            },
            [CoveragePortalFlagType.DEMOGRAPHICS_MISMATCH]: {
                severity: 50,
                label: "Demographics Mismatch",
            },
            [CoveragePortalFlagType.ADDITIONAL_POLICY_DETECTED]: {
                severity: 41,
                label: "Additional Policy",
            },
            [CoveragePortalFlagType.THIRD_PARTY_PAYER_DETECTED]: {
                severity: 40,
                label: "Third Party Payer Detected",
            },
            ///////////////////////////////////////////////////// END MEDIUM, START LOW severity
            [CoveragePortalFlagType.UNKNOWN_COSTSHARE]: {
                severity: 39,
                label: "Unknown Costshare",
            },
            [CoveragePortalFlagType.UNKNOWN_ACCUMULATOR]: {
                severity: 35,
                label: "Unknown Accumulators",
            },
            [CoveragePortalFlagType.OOP_MAX_MET]: {
                severity: 30,
                label: "OOP Max Met",
            },
            [CoveragePortalFlagType.DEDUCTIBLE_MET]: {
                severity: 25,
                label: "Deductible Met",
            },
            [CoveragePortalFlagType.NEW_PATIENT]: {
                severity: 10,
                label: "New Patient",
            },
        }
    },

    get reportsStatusCodesAggregations(): IReportsStatusCodesWidgetConfig[] {
        return [
            {
                label: "Detected Policy",
                color: NIRVANA_COLORS.brand.deepPurple,
                aggregationRules: [
                    {
                        statusCode: "200",
                    },
                    {
                        statusCode: "206",
                    },
                ],
            },
            {
                label: "Policy Not Found",
                color: NIRVANA_COLORS.brand.vibrantPurple,
                aggregationRules: [
                    {
                        statusCode: "404",
                    },
                ],
            },
            // NEEDS TO BE LAST
            {
                label: "Other Error",
                color: NIRVANA_COLORS.brand.lilacLight,
                aggregationRules: null,
            },
        ]
    },

    get reportsFlagsAggregationsConfigs(): IReportsFlagsAggregationsConfig[] {
        return [
            {
                label: "High Denial Risks",
                color: NIRVANA_COLORS.brand.warmShadow,
                flagSeverityLower:
                    POLICIES_CONFIG.highAttentionFlagSeverityCheck,
                flagSeverityHigher: 101,
            },
            {
                label: "Medium Denial Risks",
                color: NIRVANA_COLORS.brand.blue,
                flagSeverityLower:
                    POLICIES_CONFIG.mediumAttentionFlagSeverityCheck,
                flagSeverityHigher:
                    POLICIES_CONFIG.highAttentionFlagSeverityCheck,
            },
            {
                label: "Low Denial Risks",
                color: NIRVANA_COLORS.brand.pink,
                flagSeverityLower: 0,
                flagSeverityHigher:
                    POLICIES_CONFIG.mediumAttentionFlagSeverityCheck,
            },
        ]
    },

    get denialRiskMapping(): Record<PolicyDenialRisk, string> {
        return {
            [PolicyDenialRisk.HIGH]: "High",
            [PolicyDenialRisk.MEDIUM]: "Medium",
            [PolicyDenialRisk.LOW]: "Low",
            [PolicyDenialRisk.NONE]: "None",
        }
    },

    get planYearResetsStatusColors(): Record<ResetBenefitStatus, string> {
        return {
            [ResetBenefitStatus.DETECTED_ACTION_REQUIRED]:
                NIRVANA_COLORS.brand.primary,
            [ResetBenefitStatus.DETECTED_NO_ACTION_REQUIRED]:
                NIRVANA_COLORS.brand.green,
            [ResetBenefitStatus.NOT_DETECTED]: NIRVANA_COLORS.brand.lilacLight,
        }
    },
}

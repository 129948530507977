/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"

import {
    FeatureFlag,
    FeatureFlagsExistenceLogicalOperator,
} from "../config/featureFlags.config"
import { useAppSelector } from "../store/selectors/app.selector"
import { runtimeSelector } from "../store/selectors/runtime.selector"
import { FeatureFlagsHelper } from "../helpers/featureFlags.helper"
import { UtilHelper } from "../helpers/util.helper"

export const useIsFeatureFlagEnabled = (
    neededFeatureFlag: FeatureFlag | FeatureFlag[],
    existenceLogicalOperator = FeatureFlagsExistenceLogicalOperator.OR,
    redirectRouteOnMissing?: string
): boolean => {
    const navigate = useNavigate()
    const runtime = useAppSelector(runtimeSelector)

    const isEnabled = useMemo<boolean>(() => {
        return runtime.enabledFeatureFlags
            ? FeatureFlagsHelper.isFeatureFlagEnabled(
                  runtime.enabledFeatureFlags,
                  neededFeatureFlag,
                  existenceLogicalOperator
              )
            : false
    }, [runtime.enabledFeatureFlags])

    useEffect(() => {
        if (!runtime.enabledFeatureFlags || !redirectRouteOnMissing) {
            return
        }

        !isEnabled && UtilHelper.redirectTo(redirectRouteOnMissing, navigate)
    }, [runtime.enabledFeatureFlags, redirectRouteOnMissing])

    return isEnabled
}

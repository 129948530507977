import {
    FeatureFlag,
    FeatureFlagsExistenceLogicalOperator,
} from "../config/featureFlags.config"

export class FeatureFlagsHelper {
    /**
     * Check if feature enabled for current user
     * For now just simple logic, but extracted to separate function so logic can be easily made more complex
     */
    static isFeatureFlagEnabled(
        enabled: FeatureFlag[],
        featureFlagToCheck: FeatureFlag | FeatureFlag[],
        existenceLogicalOperator = FeatureFlagsExistenceLogicalOperator.OR
    ): boolean {
        const neededFeatures = Array.isArray(featureFlagToCheck)
            ? featureFlagToCheck
            : [featureFlagToCheck]

        switch (existenceLogicalOperator) {
            case FeatureFlagsExistenceLogicalOperator.AND:
                return neededFeatures.every(featureFlag => {
                    return enabled.includes(featureFlag)
                })

            case FeatureFlagsExistenceLogicalOperator.OR:
                return neededFeatures.some(featureFlag => {
                    return enabled.includes(featureFlag)
                })
        }
    }
}
